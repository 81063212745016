'use strict';
import slider2 from './modules/slider-2';
import tabs from './modules/tabs';
import servicesPicture from './modules/services-picture';
import sendForm from "./modules/send-form";
window.addEventListener('DOMContentLoaded', () => {
    slider2();
    tabs();
    servicesPicture();
    sendForm();
        //setTimeout(tlShowHeader.reverse(), 5000)
    
  // gsap.set("body", { overflow: "hidden" })

  let mm = gsap.matchMedia();
  mm.add({
    isMobile: "(max-width: 500px)",
    isDesktop: "(min-width: 501px)"
  }, (context) => {
    let { isMobile, isDesktop } = context.conditions;

    gsap.utils.toArray(".services__item").forEach((target, i) => {
      gsap.from(target, {
        scrollTrigger: {
          trigger: target,
          start: "top bottom",
          toggleActions: "play none none reverse"
        },
        opacity: 0,
        y: 50,
        width: isMobile ? "100%" : "50%",
      })
    });
  })
  
    const tlShowPage = gsap.timeline({
      defaults: { // children inherit these defaults
        duration: 2,
        ease: "power4.out"
      },
      onComplete: () => {
        // gsap.set("body", { overflow: "auto"})
        // ScrollTrigger.refresh();
      },
    });
    
    
    tlShowPage.from(".promo__right-box", { x: "50%", opacity: 0 }, "<")
              .from(".promo__left-box", { x: "-50%",  opacity: 0 }, "<")
              .from(".promo__title svg", { y: -200,  opacity: 0 }, "<");
    
    
    const tlPromoImg = gsap.timeline({
        scrollTrigger: {
          trigger: ".header",
          start: "top top",
          endTrigger: ".promo",
          end: "bottom top",
          scrub: 2,
        },
    });
    
    tlPromoImg.to(".promo__img-box img", { scale: 1.2 })
              .to(".promo__left-box img", { scale: 1.2 }, "<");
    
    const tlPromoTop = gsap.timeline({
      scrollTrigger: {
        trigger: ".promo__top",
        start: "20% top",
        scrub: 2,
        // pin: true,
        pinSpacing: false,
      },
    });
  
  
  tlPromoTop.fromTo(".promo__right-box", {x: "0", opacity: 1}, { x: "50%", opacity: 0 })
      .fromTo(".promo__left-box", { x: "0", opacity: 1 }, { x: "-50%", opacity: 0 }, "<")
      .fromTo(".promo__title svg", { y: 0}, { y: -200 }, "<")
    
    // gsap.from(".promo__small-text span", {
    //   scrollTrigger: {
    //   trigger: ".promo__small-text",
    //   start: "center bottom",
      
    //   },
    //   ease: "power4.out",
    //   duration: 1,
    //   y: 30,
    //   opacity: 0,
    //   stagger: 0.1
    // })
  
  
               
    gsap.effects.showText(".promo__small-text span", ".promo__small-text");
    gsap.effects.showText(".promo__big-text span", ".promo__big-text");
    gsap.effects.showText(".public__text span", ".public__text");
  
  
    const tlPromoList = gsap.timeline({
      scrollTrigger: {
        trigger: ".promo__list",
        start: "top bottom",
        toggleActions: "play none none reverse",
        // end: "center center",
        // scrub: 1
      },
      
      defaults: {
        duration: 1,
        // ease: "power4.out",
      }
    })
  
    tlPromoList.from(".promo__list", { x: "20%", opacity: 0 })
               .from(".promo__item", { y: 100, opacity: 0, stagger: 0.2})
               .from(".promo__item dt span", { textContent: 0,
                duration: 3,
                ease: "power4.out",
                snap: { textContent: 1 },
                stagger: 0.3 }, "<" )
               .from(".promo__images-box", { y: 150, x: 150, opacity: 0, stagger: 0.2}, "<3с0%")
               
    gsap.from(".promo__item dt", {
    scrollTrigger: {
      trigger: ".projects__list",
      start: "50px bottom",
      toggleActions: "play none none reverse",
    },
   
    // onUpdate: textContent.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
  });
    
    gsap.from(".promo__images-box img", {
      scrollTrigger: {
        trigger: ".promo__images",
        start: "top center",
        scrub: 2,
      },
      scale: 1.1,
    })
  
   
    
    gsap.from(".projects__tab", {
      scrollTrigger: {
        trigger: '.projects__tabs',
        start: "bottom bottom",
        toggleActions: "play none none reverse",
      },
      opacity: 0,
      x: 50,
      stagger: 0.1,
    });
  
  const tlNote = gsap.timeline({
    scrollTrigger: {
      trigger: ".projects__note",
      start: "top bottom",
      end: "center center",
      scrub: 1
    },
  })
  tlNote.from(".projects__note span:nth-child(2)", { y: "-100%", x: "85%" })
    .from(".projects__note span:nth-child(3)", { y: "-200%", x: "220%" }, "<")
    .fromTo(".projects__note", { opacity: 0 }, {opacity: 1, scale: 1.3}, "<")

 
  
    gsap.from(".public__item", {
      scrollTrigger: {
        trigger: ".public__list",
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      duration: 2,
      ease: "power4.out",
      x: 150,
      opacity: 0,
      stagger: 0.2
    })
  
    gsap.from(".projects__list.show .projects__item", {
      scrollTrigger: {
        trigger: ".projects__list",
        start: "50px bottom",
        toggleActions: "play none none reverse",
      },
      duration: 2,
      ease: "power4.out",
      x: 200,
      y: 200,
      stagger: 0.2,
    });
  
    gsap.from(".column", {
      scrollTrigger: {
        trigger: ".row",
        start: "top bottom",
        toggleActions: "play none none reverse",
      },
      duration: 1.5,
      ease: "power4.out",
      opacity: 0,
      x: 250,
      stagger: 0.1
    })
  
    gsap.from(".feedback__input", {
      scrollTrigger: {
        trigger: ".feedback__form",
        start: "100 bottom",
        toggleActions: "play none none reverse",
      },
      duration: 4,
      ease: "power4.out",
      opacity: 0,
      width: 200,
      stagger: 0.2
    })
  
    const tlFeedback = gsap.timeline({
      scrollTrigger: {
        trigger: ".feedback__bottom",
        start: "bottom bottom",
        toggleActions: "play none none reverse",
      },
      defaults: {
        duration: 1,
      }
    })
  
    tlFeedback.from(".feedback__agree", { y: 50, opacity: 0 })
              .from(".feedback__btn", { y: 50, opacity: 0}, "<50%")
  
  
  //   let targets = gsap.utils.toArray(".promo__item dt"); 
  // gsap.from(targets, {
  //   scrollTrigger: {
  //     trigger: ".promo__list",
  //     start: "top center",
  //     scrub: 1,
  //     markers: true,
  //   },
  //   y: "random(-200, 0)"
  //  })
});