function tabs () { 
    let tabs = document.querySelectorAll('.project-en'),
        tabsRu = document.querySelectorAll('.project-ru')
		tabsContent = document.querySelectorAll('.projects__list'),
		tabsParent = document.querySelector('.projects__tabs');
	function hideTabContent() {
        
        tabsContent.forEach(item => {
            item.classList.add('hide');
            item.classList.remove('show', 'fade');
        });

        tabs.forEach(item => {
            item.classList.remove('project_active');
        });

        tabsRu.forEach(item => {
            item.classList.remove('project_active');
        })
	}

	function showTabContent(i = 0) {
        tabsContent[i+1].classList.add('show', 'fade');
        tabsContent[i+1].classList.remove('hide');
        tabs[i].classList.add('project_active');
        tabsRu[i].classList.add('project_active');
    }

    hideTabContent();
    tabsContent[0].classList.add('show', 'fade');
    tabsContent[0].classList.remove('hide');

	tabsParent.addEventListener('click', function(event) {
		const target = event.target;
		if(target.parentNode && target.parentNode.classList.contains('projects__tab')) {
            tabs.forEach((item, i) => {
                
                if (target === item) {
                    hideTabContent();
                    showTabContent(i);
                    gsap.from('.projects__list.show .projects__item', {
                        duration: 2,
                        ease: "power4.out",
                        x: 200,
                        y: 200,
                        stagger: 0.2,
                    });
                }
            });
		}
        if(target.parentNode && target.parentNode.classList.contains('projects__tab')) {
            tabsRu.forEach((item, i) => {
                
                if (target === item) {
                    hideTabContent();
                    showTabContent(i);
                    gsap.from('.projects__list.show .projects__item', {
                        duration: 2,
                        ease: "power4.out",
                        x: 200,
                        y: 200,
                        stagger: 0.2,
                    });
                }
            });
		}
    });
}
module.exports = tabs;