function sendForm () {

      let form = document.getElementById("form"),
          serverResponse = document.querySelector('.feedback__response')

        form.addEventListener("submit", async function (event) {
            event.preventDefault();

            let response = await fetch('/mailer/mail.php', {
                method: 'POST',
                body: new FormData(form)
              });

            if (response.ok) {
                console.log("send");
                serverResponse.innerHTML = "Message has been sent"
            } else {
                console.log("error: " + response.status);
                serverResponse.innerHTML = "Message could not be sent"
            }

            let tlHideForm = gsap.timeline({})
            tlHideForm
            .to(".feedback__input", {
                duration: 4,
                ease: "power4.out",
                autoAlpha: 0,
                width: 200,
                stagger: 0.2
            })
            .fromTo(serverResponse, { opacity: 0, y: 50, duration: 1}, {opacity: 1, y: 0,}, "<10%")
            .to(".feedback__bottom", { y: 50, autoAlpha: 0}, "<50%")
      })

}
module.exports = sendForm;