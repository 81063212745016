function servicesPicture () {
    
    let pictureBox = document.querySelector('.services__img-box')
        picture = document.querySelectorAll('.services__img'),
        pictureContainer = document.querySelector('.services__img-container'),
        itemsParent = document.querySelector('.services__list'),
        listItems = document.querySelectorAll('.services__item')

        function hideContent() {
            pictureBox.classList.add('hide');
        }
        hideContent();
        function showContent() {
            pictureBox.classList.remove('hide');
        }

        itemsParent.addEventListener('mouseenter', function(event) {
            const target = event.target;
            if(target && target.classList.contains('services__list')) {
                showContent();
            }
        });

        itemsParent.addEventListener('mouseleave', function(event) {
            const target = event.target;
            if(target && target.classList.contains('services__list')) {
                hideContent();
            }
        });
        let mouseX = 0, mouseY = 0, posX = 0, posY = 0, coords

        let dif = 0

        itemsParent.onmousemove = function(event) {
            event = event || window.event;
            // pictureBox.style.top = `${event.pageY - 155}px`;
            coords = itemsParent.getBoundingClientRect();

            mouseX = event.clientX
            mouseY = event.clientY
            
            posY =  mouseY -  coords.top
            
        }

        gsap.to({}, .01, {
            repeat: -1,
            onRepeat: () => {
        
            //posX += (mouseX - posX) / 5
            //   posY += (mouseY - posY) / 15
            dif += (posY - dif)/15
        
              gsap.set(pictureBox, {
                css: {
                //   left: posX - 24,
                  top: dif
                }
              })
            }
          })


        function seeScroll (event) {
            document.addEventListener('scroll', function() {
                event = event || window.event;
                    // let top = pictureBox.getBoundingClientRect().top
                    // console.log(top);
            });
        }
        seeScroll ();
        // function getPosition(e){
        //     let y = 0;
         
        //     if (!e) {
        //         var e = window.event;
        //     }
         
        //     if (e.pageY){
        //         y = e.pageY;
        //     } else if (e.clientY){
                
        //         y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        //     }
        //     console.log(y);
        //     return {y: y}
        // }
        // getPosition();
        
        function coolPicture() {
            let rotate = 90
            listItems.forEach((item, i) => {
              item.addEventListener('mouseenter', function (event) {
                const target = event.target;
                rotate +=90;
                if (target && target.classList.contains("services__item")) {
                    item[i];
                    picture[i];
                    gsap.to(picture, {opacity: 0})
                    gsap.to(picture[i], {opacity: 1})
                    // picture.setAttribute("src", pictureName[i]);
                  gsap.fromTo(pictureBox, { scale: 0.9 }, { rotate: rotate, scale: 1 });
                  gsap.to(pictureContainer, { rotate: -rotate, duration: 0.5, ease: "power1.out" });
                  return i;
                }
              });
            });
          }
        
	coolPicture();
}

module.exports = servicesPicture;