function slider2 () {
    const rowWrapper = document.querySelector('.row-wrapper');
    const rowSlidefield = document.querySelector('.row');
    const slides = document.querySelectorAll('.column');
    const slideImages = document.querySelectorAll('.slide__img>img');
    const firstSlide = document.querySelector('.first-block');
    const nextSlide = document.querySelector('.next-slide');
    const prevSlide = document.querySelector('.prev-slide');
    const lastSlide = document.querySelector('.last-block');
    let sum = 0;
    let centerOffset;
    let lastOffset;

   
    function countWrapper () {
        const len = [];
        slideImages.forEach(item => {
            len.push(item.naturalWidth);
        });
        len.map((item) => sum += item);
    }
    countWrapper ();

    function getWidth() {
        rowSlidefield.style.width = window.screen.width + 'px';
        if (window.screen.width <= 1920) {
            rowSlidefield.style.width = 2290 + 'px';
        }
        if (window.screen.width <= 1280) {
            rowSlidefield.style.width = 1600 + 'px';
        }
        if (window.screen.width <= 991) {
            rowSlidefield.style.width = 90 + '%';
        }
    }
    getWidth();
    
    function countScroll () {
        let screenWidth = window.screen.width;
        console.log(screenWidth);
        if (window.screen.width > 1920) {
            rowWrapper.style.display = 'flex';
            rowWrapper.style.justifyContent = 'center';
        }
        centerOffset = (parseInt(rowSlidefield.style.width) - screenWidth)/2;
        lastOffset = (parseInt(rowSlidefield.style.width) - screenWidth);
        if(window.screen.width <= 1920) {
            centerOffset = (2290 - screenWidth)/2;
            lastOffset = (2290 - screenWidth);
        }
        if (window.screen.width <= 1280) {
            centerOffset = (1600 - screenWidth)/2;
            lastOffset = (1600 - screenWidth);
        }
        return centerOffset, lastOffset;
        
    }
    countScroll ();
    if (window.screen.width <= 1920) {
        rowSlidefield.style.transform = `translateX(-${centerOffset}px)`;
    }
    
    //console.log(centerOffset)
    function clickImage (trigger, rotate) {
        trigger.addEventListener('click', () => {
            rowSlidefield.style.transform = `translateX(-${rotate}px)`;
        }); 
    } 

    if(window.screen.width <= 1920) {
        rowWrapper.addEventListener('mousedown', mouseTouchStart, false);
        rowWrapper.addEventListener('mouseup', mouseTouchMove, false);
        rowWrapper.addEventListener('touchstart', handleTouchStart, false);
        rowWrapper.addEventListener('touchmove', handleTouchMove, false);
    
    }
   
    let mouseX1 = null;
    function mouseTouchStart (event) {
        mouseX1 = event.x;
    }
   
    function mouseTouchMove (event) {
        if (!mouseX1) {
            return false;
        }
        let mouseX2 = event.x;
        let mouseXDiff = mouseX2 - mouseX1;
        console.log(mouseX2);
        if (mouseXDiff > 0) {
            rowSlidefield.style.transform = `translateX(0px)`;
        } else {
            rowSlidefield.style.transform = `translateX(-${lastOffset}px)`;
        }
        mouseX1 = null;
    }
    let x1 = null;
    function handleTouchStart (event) {
        const firstTouch = event.touches[0];
        x1 = firstTouch.clientX;
        console.log(x1);
    }

    function handleTouchMove (event) {
        if (!x1) {
            return false;
        }
        let x2 = event.touches[0].clientX;
        let xDiff = x2 - x1;
        console.log(x2);
        if (xDiff > 0) {
            console.log('hi');
            rowSlidefield.style.transform = `translateX(0px)`;
        } else {
            console.log('by');
            rowSlidefield.style.transform = `translateX(-${lastOffset}px)`;
        }
        
        x1 = null;
    }
    if (window.innerWidth <= 991) {
        const slideText = document.querySelectorAll('.slide__text');
        slideText.forEach(item => {
            item.style.display = 'none';
        })
    }

    clickImage(firstSlide, '0');
    clickImage(prevSlide, centerOffset);
    clickImage(nextSlide, centerOffset);
    clickImage(lastSlide, lastOffset);
    if(window.screen.width <= 991) {
        rowSlidefield.style.transform = `translateX(0px)`;
        clickImage(prevSlide, '0');
        clickImage(nextSlide, '0');
        clickImage(lastSlide, '0');
        lastOffset = 0;
    }
}
module.exports = slider2;
   